import { useState } from "react";
import isEmail from "validator/lib/isEmail";
import FadeIn from "react-fade-in";
import logo from "./logo.svg";
import "./App.css";

import axios from "axios";

function App() {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [userError, setUserError] = useState(false);
  const [email, setEmail] = useState("");

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (submitted === true) {
      return;
    }
    if (email === "") {
      setUserError("Please enter an email address");
    } else if (isEmail(email) === false) {
      setUserError("Please enter a valid email address");
    } else {
      axios
        .post("https://europe-west3-upglow-1.cloudfunctions.net/api/submit", {
          email: email,
        })
        .then(() => {
          setSubmitted(true);
          setEmail("");
        })
        .catch(() => {
          setError(true);
        });
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setUserError(false);
    setSubmitted(false);
    setError(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <FadeIn delay={100}>
          <img src={logo} className="logo" alt="logo" />
        </FadeIn>
        <FadeIn delay={500}>
          <p className="text">It's about time we disrupt beauty services.</p>
        </FadeIn>
        <FadeIn delay={1000}>
          <form onSubmit={onFormSubmit}>
            <div className="field-wrapper">
              <input
                id="email"
                type="text"
                name="email"
                className="field"
                onChange={(e) => handleChange(e)}
                placeholder="example@mail.com"
              ></input>
              <button className="button" type="submit">{`Learn how`}</button>
            </div>
          </form>
        </FadeIn>
        {submitted && (
          <div className="message">
            Thanks! We'll send you an update when it's ready.
          </div>
        )}
        {userError && <div className="errormessage">{userError}</div>}
        {error && <div className="errormessage">{error}</div>}
      </header>
    </div>
  );
}

export default App;
